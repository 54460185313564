




import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import i18n from "@/i18n";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class RemoveButton extends mixins(ButtonMixin) {
  public name = "RemoveButton";

  @Prop({ default: () => i18n.t("calendar.remove_button") })
  public tooltipLabel!: string;
}
